/* eslint-disable indent */
import * as React from "react";
import CheckBoxNeoGenControlled from "../../layout/checkbox-controlled";
import SelectNeoGen from "../../layout/select-neogen";
import states from "../../sections/utilities/states.json";
import TextAreaNeoGenControlled from "../../layout/text-area-controlled";
import InputControlled from "../../layout/input-controlled";
import RadioButtonNeoGenControlled from "../../layout/radiobutton-neogen-controlled";
import FileUploadWidget from "../../layout/file-upload-widget";
import { FileUpload, FileUploadWithMultiple } from "../../typings/api/file-upload";
import { calculate } from "../../sections/utilities/calculate";
import CalendarWidget from "../../sections/utilities/calendar-widget";
import PrintPre from "../../layout/print-pre";
import ButtonNeoGen from "../../layout/button";
import { Entry } from "../processflows/domain/processflow";
import { ButtonActionTypes } from "../../layout/button-creator";
import { API_URL } from "../../services/api.service";
import { useBreakpoint } from "../../hooks/appMedia";
import { PaymentOptions, paymentOptionsKey } from "../../billing/paymentOptions";
import { FinanceOptions } from "../../billing/financeOptions";
import { getEstimatedPayoutFromData } from "../other/actions/getEstimatedPayout";

type OnClick = (action: ButtonActionTypes) => void;

const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

export const renderEntry = ({
    entry,
    entryErrors,
    onChange,
    onClick,
    data,
    allEntries,
    noMargin,
    drawWithFlex,
    isMobile,
    isTablet,
    getFileKey,
}: {
    entry: Entry;
    entryErrors?: string[];
    onChange: (value: any) => void;
    onClick: OnClick;
    data: Record<string, any>;
    allEntries: any;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    isMobile?: boolean;
    isTablet?: boolean;
    getFileKey?: (entry: Entry) => string;
}) => {
    const estimatedAmount = getEstimatedPayoutFromData({ data });

    if (entry.field === paymentOptionsKey) {
        return (
            <PaymentOptions
                estimatedAmount={estimatedAmount}
                defaultValue={data[entry.field]}
                onChange={(id) => {
                    onChange(id);
                }}
            />
        );
    }

    if (entry.field === "finance_options") {
        return (
            <FinanceOptions
                estimatedAmount={estimatedAmount}
                defaultValue={data[entry.field]}
                onChange={(id) => {
                    onChange(id);
                }}
            />
        );
    }

    let width = "";

    switch (entry.widthOf300Cols) {
        case 300:
            width = "  w-full ";
            break;
        case 200:
            width = "  w-2/3 inline-block md:pr-5 ";
            break;
        case 150:
            width = "  w-1/2 inline-block md:pr-5 ";
            break;
        case 100:
            " w-full inline-block align-top " +
                " md:align-top md:w-1/2 md:pr-5 " +
                "  lg:align-top lg:w-1/3 lg:inline-block lg:pr-5 ";
            break;
        case 75:
            width = isMobile
                ? " w-full inline-block "
                : isTablet
                ? " w-1/2 inline-block align-top pr-5 "
                : "  w-1/4 inline-block pr-5 align-top ";
            break;
        case 60:
            width = "  w-1/5 inline-block md:pr-5 h-full ";
            break;
        default:
            width = "  w-full inline-block ";
            break;
    }

    width += " my-1 top-0  left-0 h-full ";
    try {
        switch (entry.type) {
            case "textDisplay":
                return (
                    <div className={" " + width + (noMargin ? " m-0" : " mb-5")}>
                        <p className="text-sm text-gray-500">
                            <span className="text-sm text-gray-500">{entry.value}: </span>
                            {/* <b>{typeof (data[entry.field] === "String") ? data[entry.field] : ""}</b> */}
                            {typeof data[entry.field] === "string" ? data[entry.field] : typeof data[entry.field]}
                            {typeof data[entry.field] !== "string" && <PrintPre>{entry.field}</PrintPre>}
                        </p>
                    </div>
                );
            case "video":
                return (
                    <div className={" " + width + " mb-5"}>
                        <span className="text-sm text-gray-500">{entry.value}</span>
                        <video controls>
                            <source src={FILE_API_URL + "/files/" + entry.field} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            case "checkbox":
                return (
                    <div className={" " + width + " mb-5"}>
                        <CheckBoxNeoGenControlled
                            setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange(e.currentTarget.checked);
                            }}
                            value={data[entry.field]}
                            name={entry.field}
                            label={entry.value}
                            description={entry.description}
                            helpDocument={entry.helpDocument}
                            required={entry.required}
                        />
                    </div>
                );
            case "state":
                return (
                    <div className={" " + width + " mb-5"}>
                        <SelectNeoGen
                            onChange={(e: string | number) => {
                                onChange(e.toString());
                            }}
                            value={data[entry.field]}
                            options={states}
                            label={entry.value}
                            required={entry.required}
                        />
                        <p className="text-sm text-gray-500">{entry.description}</p>
                    </div>
                );
            case "textarea":
                return (
                    <div className={" " + width + " mb-5"}>
                        <TextAreaNeoGenControlled
                            setValue={(e: string) => {
                                onChange(e);
                            }}
                            value={data[entry.field]}
                            name={entry.field}
                            label={entry.value}
                            description={entry.description}
                            required={entry.required}
                        />
                    </div>
                );
            case "textfield":
                return (
                    <div className={" " + width + " self-start"}>
                        <InputControlled
                            setValue={(e: string) => {
                                onChange(e);
                            }}
                            required={entry.required}
                            password={entry.password}
                            type={entry.password ? "password" : "text"}
                            value={data?.[entry.field] ?? ""}
                            name={entry.field}
                            label={entry.value}
                            helpDocument={entry.helpDocument}
                            description={entry.description}
                            errors={entryErrors}
                        />
                    </div>
                );
            case "valueCalculator":
                // Doesn't draw anything - it gets included in HTML
                break;
            case "yesNo":
                return (
                    <div className={" " + width + "  mb-5"}>
                        <RadioButtonNeoGenControlled
                            onChange={(e: string | number) => {
                                onChange(e.toString());
                            }}
                            value={data?.[entry.field]}
                            options={[
                                { id: "1", name: "Yes" },
                                { id: "0", name: "No" },
                            ]}
                            id={entry.field}
                            label={entry.value}
                            helpDocument={entry.helpDocument}
                            required={entry.required}
                        />
                        <p className="text-sm text-gray-500">{entry.description}</p>
                    </div>
                );
            case "selectBox":
                return (
                    <div className={" " + width + "  mb-5 "}>
                        <RadioButtonNeoGenControlled
                            onChange={(e: string | number) => {
                                onChange(e.toString());
                            }}
                            value={data?.[entry.field]}
                            options={entry.selectBoxEntries?.map((selectBoxEntry: any) => {
                                return {
                                    id: selectBoxEntry,
                                    name: selectBoxEntry,
                                };
                            })}
                            helpDocument={entry.helpDocument}
                            id={entry.field}
                            label={entry.value}
                            required={entry.required}
                        />
                        <p className="text-sm text-gray-500">{entry.description}</p>
                    </div>
                );
            case "fileUpload":
                width = " w-full lg:w-1/2 min-[2048px]:w-1/3 min-[3000px]:w-1/4 inline-block md:pr-5 ";
                return (
                    <div className={"text-left md:text-center " + width + " "}>
                        <FileUploadWidget
                            entry={entry}
                            fileDetails={data?.[entry.field]}
                            helpDocument={entry.helpDocument}
                            name={entry.field}
                            label={entry.value}
                            setFileDetails={(fileDetails: FileUploadWithMultiple) => {
                                onChange(fileDetails);
                            }}
                            required={entry.required}
                            fileKey={getFileKey ? getFileKey(entry) : undefined}
                        />
                        <p className="text-sm text-gray-500">{entry.description}</p>
                    </div>
                );
            case "button": {
                const button = JSON.parse(entry.value);
                return (
                    <div className={" inline-block mr-5 mb-5" + " "}>
                        <ButtonNeoGen
                            icon={
                                (button.action === "nextStep" && "fas fa-chevron-right") ||
                                (button.action === "previousStep" && "fas fa-chevron-left") ||
                                (button.action === "nextStage" && "fas fa-chevron-right") ||
                                (button.action === "previousStage" && "fas fa-chevron-left") ||
                                (button.action === "submit" && "fal fa-check") ||
                                (button.action === "save" && "fal fa-save") ||
                                (button.action === "cancel" && "fal fa-times") ||
                                (button.action === "reset" && "fal fa-undo") ||
                                (button.action === "custom" && button.icon)
                            }
                            type={button.colorType !== "custom" ? button.colorType : undefined}
                            size={button.size}
                            onClick={() => {
                                // TODO: Change this to an atom
                                localStorage.setItem("button", JSON.stringify(button));
                                onClick(button.action);
                            }}
                            iconAlign={["previousStep", "previousStage"].includes(button.action) ? "left" : "right"}
                            text={button.label}
                        />
                    </div>
                );
            }
            case "wysiwyg": {
                // An HTML string

                // In the HTML sections you can click a button to insert a field's value
                // Basically the way it works is that you have a button that inserts the '
                // field name inside double curly braces. When we are displaying the HTML
                // we replace the double curly braces with the actual value of the field.
                // This could be the value the customer entered or it could be a calculation
                // of other fields.
                //
                // Replace {{fieldName}} with the value of the field with the same name
                // across all the questions in the current progress flow group.
                // console.info("Drawing: ",entry)

                // eslint-disable-next-line no-case-declarations
                const html = entry.value
                    .replace(/\[\[(.*?)\]\]/g, (_match: string, fieldFound: string) => {
                        // Replace [[filename]] with a link to the video
                        return `
                    <div className={" " + width + " mb-5"}>
                    <video  controls>
                        <source
                            src="${FILE_API_URL}/files/${fieldFound}" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </div>
                        `;
                    })
                    .replace(/{{(.*?)}}/g, (_match: string, fieldFound: string) => {
                        // Look for the field value in the current step. If it's not there then
                        // either the customer hasn't entered it yet or it's not in the current
                        // step. So we look for it in the previous steps.
                        // The other option is that the field is a calculation, in which case
                        // we need to look for the field values to calculate it.
                        // The val variable will hold the value of the field and its details.
                        const val: Entry = data?.[fieldFound];

                        // If the field type is a state, return the long form state name.
                        if (val?.type === "state") {
                            return states.find((s: any) => s.id === val.fieldValue)?.name ?? "";
                        }

                        // If the fiield type is a checkbox, return it as Yes or No
                        if (val?.type === "checkbox") {
                            return val.fieldValue ? "Yes" : "No";
                        }

                        // If we didn't find the field value it might just have not been filled out yet.
                        // We should know from the field type if it's a calculation or not.

                        // First look through all entries and see if we can find the field name.
                        const field: Entry | undefined = (allEntries ?? []).find((e: any) => {
                            return e.id === fieldFound;
                        });

                        if (!val && field?.type === "valueCalculator") {
                            try {
                                let total = 0;
                                const foundFull = allEntries?.find((p: any) => p.id === fieldFound);
                                const found = foundFull.value;

                                if (found) {
                                    for (const calculation of JSON.parse(found)) {
                                        let val1 = 0;
                                        let val2 = 0;
                                        if (calculation.field1 === "**num") {
                                            val1 = calculation.field1number;
                                        } else {
                                            val1 = data?.[calculation.field1];
                                        }
                                        if (calculation.field2 === "**num") {
                                            val2 = calculation.field2number;
                                        } else {
                                            val2 = data?.[calculation.field2];
                                        }
                                        const opResult = calculate(val1, val2, calculation.operator);
                                        total += opResult;
                                    }
                                }
                                return (Number.isNaN(total) ? 0 : total).toLocaleString();
                            } catch (e) {
                                return "(<></>)";
                            }
                        }
                        return val?.fieldValue ?? "";
                    })
                    .replace(/\n/g, "<br />");

                return (
                    <div key={entry.id} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
                        {/* <PrintPre>
                          {entry.value.replace(/\\"/g, "\"")}
                        </PrintPre> */}
                        <div className="ql-snow ql-read-only " dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                );
            }
            case "datePicker": {
                return (
                    <div className={" " + width + " "}>
                        <CalendarWidget
                            onChange={(e: Date) => {
                                onChange(e);
                            }}
                            value={data?.[entry.field] && new Date(data[entry.field])}
                            label={entry.value}
                            description={entry.description || ""}
                        />
                    </div>
                );
            }
            default:
                return (
                    <div>
                        {/* <PrintPre>{entry}</PrintPre> */}
                        {/* <PrintPre>{allEntries}</PrintPre> */}
                    </div>
                );
        }
    } catch (e: any) {
        console.error(e);
    }
};

export const Entries = ({
    entries,
    entryErrors,
    onChange,
    onClick,
    data,
    noMargin,
    drawWithFlex,
    getFileKey,
}: {
    entries: Entry[];
    entryErrors?: Record<string, string[]>;
    onChange: ({ entry, value }: { entry: Entry; value: any }) => void;
    onClick: OnClick;
    data: Record<string, any>;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    getFileKey?: (entry: Entry) => string;
}) => {
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <div className={drawWithFlex ? "grid-cols-2 w-full overflow-hidden grid " : ""}>
            {entries.map((entry) => {
                return (
                    <span key={entry.id} className={drawWithFlex ? " " : ""}>
                        {renderEntry({
                            entry,
                            entryErrors: entryErrors?.[entry.field],
                            data,
                            onChange: (value) => {
                                onChange({ entry, value });
                            },
                            onClick,
                            allEntries: entries,
                            noMargin,
                            isMobile: isMobile,
                            isTablet: isTablet,
                            getFileKey,
                        })}
                    </span>
                );
            })}
        </div>
    );
};
